* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.print-table th{
  color: rgba(0, 0, 0, 0.65);
  text-align: right;
}

@media print {
  table.print-table th, table.print-table td {
    border: solid white !important;
    border-width: 0 1px 1px 0 !important;
    border-bottom-style: none;
    padding: 2px 10px;
  }

  table .ant-table-thead > tr > th, table .ant-table-tbody > tr > td {
    padding: 5px 2px;
  }

  table { page-break-after:auto }
  tr    { page-break-inside:avoid; page-break-after:auto }
  td    { page-break-inside:avoid; page-break-after:auto }
  thead { display:table-header-group }
  tfoot { display:table-footer-group }

  .no-print {
    display: none;
  }
}
